import React, {Fragment, useMemo} from 'react';
import styled from 'styled-components';
import {useDimension} from '../../Hooks/AppHooks';
import {Actions, Context, NavActions} from '../../Contexts/AppContext';
import NewsItem from './NewsItem';
import queryString from 'querystring';
import * as L from '../../Utils/Lang';
import {ChevronLeft, Copy, Facebook, Share} from '../../Components/SvgIcon';
import {
  Color,
  CustomNavBar,
  FlexColCenter,
  FlexRow,
  SeparateLine,
} from '../../Components/Widget';
import RectButton, {
  BUTTON_SIZE,
  BUTTON_SKIN,
} from '../../Components/RectButton';
import {goTo} from '../../Utils/UrlUtils';
import {useFetchNews, useFetchNewsList} from '../../Hooks/NewsHooks';
import FixedRatioImage from '../../Components/FixedRatioImage';
import {brandsDisplayArr} from '../../Domain/Brand';
import * as SvgIcon from '../../Components/SvgIcon';
import Constant from '../../constants';
import CopyToClipboard from '../../Components/CopyToClipboard';

const SimpleNewsItem = props => {
  const {news, mobile, tablet} = props;
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: `${mobile ? '0 0 48px 0' : '0 12px 48px 12px'}`,
        minWidth: mobile ? '280px' : tablet ? '336px' : '430px',
      }}>
      <div
        style={{cursor: 'pointer'}}
        onClick={() => {
          NavActions.navigate(`/news/detail?id=${news.id}`);
        }}>
        <FixedRatioImage ratio={242 / 430} width={'100%'} image={news.image} />
      </div>
      <h3 style={{marginTop: 16, marginBottom: 12}}>{L.d(news.title)}</h3>
      <small style={{color: Color.mainDark_70}}>{news.start_date}</small>
    </div>
  );
};

const SimpleNewsItemFiller = props => {
  const {mobile, tablet} = props;
  return (
    <div
      style={{
        flex: 1,
        minWidth: mobile ? '280px' : tablet ? '336px' : '430px',
        height: '1px',
        margin: `0 12px`,
        backgroundColor: 'transparent',
        visibility: 'hidden',
      }}
    />
  );
};

function DetailPage(props) {
  const {location} = props;
  const app = React.useContext(Context);
  const {dimension} = useDimension();
  const params = queryString.parse(location.search.split('?')[1]);
  const id = params?.id;
  const {news, isLoading} = useFetchNews(id);
  const {newsList} = useFetchNewsList();
  const mobile = dimension.innerWidth <= app.breakpoints.mobile;
  const tablet = dimension.innerWidth <= app.breakpoints.tablet;

  let isApp = false;
  try {
    isApp = params?.app === '1';
  } catch (err) {}

  if (!dimension?.innerWidth || !newsList) {
    return null;
  }

  return (
    <Fragment>
      {tablet && !isApp && (
        <CustomNavBar>
          <ChevronLeft
            onClick={() => {
              NavActions.navigate('/news');
            }}
          />
          <Share
            onClick={() => {
              if (navigator.share) {
                const shareData = {
                  title: 'tungrp',
                  url: window.location.href,
                };
                navigator
                  .share(shareData)
                  .then(() => console.log('成功！'))
                  .catch(error => console.log('發生錯誤', error));
              }
            }}
          />
        </CustomNavBar>
      )}
      {!news && !isLoading && (
        <Wrapper mobile={mobile}>
          <FlexColCenter
            style={{
              width: 280,
              textAlign: 'center',
              alignSelf: 'center',
              margin: '120px 0',
            }}>
            <h1>OOPS!</h1>
            <p
              style={{
                padding: '0 10px',
                marginTop: 16,
                marginBottom: 48,
                color: Color.mainDark_70,
              }}>
              很抱歉，您造訪的頁面已經不存在
              更多最新消息推薦給您，也歡迎回到首頁繼續瀏覽
            </p>
            <RectButton
              skin={BUTTON_SKIN.DEFAULT}
              text="返回首頁"
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          </FlexColCenter>
          <div
            className="separate"
            style={{
              width: '100%',
              borderBottom: `1px solid ${Color.mainDark_10}`,
            }}
          />
          <div>
            <FlexRow
              style={{
                marginTop: 56,
                marginBottom: 24,
                justifyContent: 'space-between',
              }}>
              <h3>最新消息</h3>
              <div style={{flex: 1}} />
              <div
                onClick={() => {
                  NavActions.navigate('/news');
                }}
                className="more">
                All News <div className="see-more-arrow" />
              </div>
            </FlexRow>
            <FlexRow
              style={{
                width: '100%',
                justifyContent: 'left',
                flexWrap: 'wrap',
              }}>
              {newsList.map((news, index) => (
                <SimpleNewsItem
                  key={index}
                  news={news}
                  mobile={mobile}
                  tablet={tablet}
                />
              ))}
              {new Array(3).fill(0).map((_item, index) => (
                <SimpleNewsItemFiller
                  key={index}
                  mobile={mobile}
                  tablet={tablet}
                />
              ))}
            </FlexRow>
          </div>
        </Wrapper>
      )}
      {news && (
        <Wrapper key={id} mobile={mobile}>
          {!tablet && (
            <FlexRow
              style={{
                fontSize: 14,
                fontWeight: 300,
                color: Color.mainDark_70,
                marginBottom: 20,
              }}>
              <span
                style={{cursor: 'pointer'}}
                onClick={() => {
                  NavActions.navigate('/home');
                }}>
                {brandsDisplayArr.find(b => b.key === app.activeBrand).display}
              </span>
              <SvgIcon.ChevronRight size={16} color={Color.mainDark_70} />{' '}
              <span
                onClick={() => {
                  NavActions.navigate('/news');
                }}
                style={{cursor: 'pointer'}}>
                最新消息
              </span>
              <SvgIcon.ChevronRight size={16} color={Color.mainDark_70} />{' '}
              <span style={{cursor: 'pointer'}}>{L.d(news.title)}</span>
            </FlexRow>
          )}

          <div className="banner-img-wrapper">
            <img src={news.image} alt="news" />
          </div>

          <div className="main">
            <div className="left">
              <div className="date">{news.date}</div>
              <div className="title">{L.d(news.title)}</div>
              <SeparateLine />
              <div className="content">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{__html: L.d(news.description)}}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: mobile ? 'column' : 'row',
                }}>
                {news.buttons.map((btn, index) => (
                  <RectButton
                    key={index}
                    style={{margin: 12}}
                    skin={BUTTON_SKIN.DEFAULT}
                    size={BUTTON_SIZE.MEDIUM}
                    text={btn.text}
                    onClick={() => {
                      goTo(btn.link_to, NavActions);
                    }}
                  />
                ))}
              </div>
              <SeparateLine />

              <div
                style={{
                  display: 'flex',
                  flexDirection: mobile ? 'column' : 'row',
                }}>
                {news.prev && (
                  <div style={{flex: 1}}>
                    <div
                      onClick={() => {
                        NavActions.navigate(`/news/detail?id=${news.prev.id}`);
                      }}
                      style={{
                        marginBottom: 10,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        fontSize: 16,
                        lineHeight: '24px',
                      }}>
                      Previous
                    </div>
                    <NewsItem
                      style={{margin: 0, padding: 0}}
                      item={news.prev}
                      inDetailPage
                      onClick={() => {
                        NavActions.navigate(`/news/detail?id=${news.prev.id}`);
                      }}
                    />
                  </div>
                )}

                {news.next && (
                  <div style={mobile ? {height: 48} : {width: 20}} />
                )}

                {news.next && (
                  <div style={{flex: 1, marginBottom: 64}}>
                    <div
                      onClick={() => {
                        NavActions.navigate(`/news/detail?id=${news.next.id}`);
                      }}
                      style={{
                        textAlign: mobile ? 'left' : 'right',
                        marginBottom: 10,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        fontSize: 16,
                        lineHeight: '24px',
                      }}>
                      Next
                    </div>
                    <NewsItem
                      style={{margin: 0, padding: 0}}
                      onClick={() => {
                        NavActions.navigate(`/news/detail?id=${news.next.id}`);
                      }}
                      item={news.next}
                      inDetailPage
                    />
                  </div>
                )}
              </div>
            </div>

            {!mobile && (
              <div className="right">
                <a
                  href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                  target="_blank">
                  <Facebook style={{cursor: 'pointer'}} h={40} w={40} />
                </a>
                <CopyToClipboard
                  text={window.location.href}
                  showTooltip={false}>
                  <Copy
                    onClick={() => {
                      document.execCommand('copy', true, window.location.href);
                      Actions.setGlobalToastContent(
                        <label style={{color: '#fff', padding: '20px 0'}}>
                          複製成功
                        </label>,
                      );
                      setTimeout(() => {
                        Actions.setGlobalToastContent(null);
                      }, 2000);
                    }}
                    style={{cursor: 'pointer'}}
                    color="#141414"
                    h={40}
                    w={40}
                  />
                </CopyToClipboard>
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </Fragment>
  );
}

const Wrapper = styled.div`
  padding: ${props => (props.mobile ? '0px 20px 20px 20px' : '40px 50px')};
  display: flex;
  flex-direction: column;

  & > .banner-img-wrapper {
    width: 100%;
    padding-top: 65%;
    background-color: #ccc;
    margin-bottom: 40px;
    position: relative;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }

  & > .main {
    display: flex;

    & > .left {
      flex: 1;
      padding: ${props => (props.mobile ? '0px' : '0px 80px')};

      & > .date {
        font-size: 14px;
        line-height: 1.57;
        color: rgba(20, 20, 20, 0.7);
        margin-bottom: 12px;
      }

      & > .title {
        font-size: 30px;
        font-weight: bold;
        line-height: 1.27;
      }
      & > .content {
        margin: 40px 0;
        & img {
          margin: 32px 0;
          width: 100%;
        }
      }
    }

    & > .right {
      width: 180px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > button {
        margin-bottom: 20px;
      }
    }
  }

  .more {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      & .see-more-arrow {
        width: 48px;
      }
    }
  }

  & .see-more-arrow {
    position: relative;
    width: 24px;
    background-color: ${Color.mainDark_70};
    height: 1px;
    margin-left: 12px;
    transition: width 0.1s ease-in;
    &:before {
      content: '';
      width: 8px;
      display: block;
      background-color: ${Color.mainDark_70};
      height: 1px;
      transform: rotate(30deg);
      transform-origin: right;
      position: absolute;
      right: 1px;
    }
  }
`;

export default DetailPage;
